import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getAccounts } from "../services/account";

const initialState = {
  isLoading: false,
  accountNames: [],
};

export const getAccountAction = createAsyncThunk("accounts", async () =>
  getAccounts()
);

const accountSlice = createSlice({
  name: "accounts",
  initialState,
  extraReducers: {
    [getAccountAction.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getAccountAction.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.accountNames = action.payload.accountNames;
    },
  },
});

export default accountSlice.reducer;
