import React, { useEffect, useState } from "react";
import { AppBar, Button, Toolbar } from "@mui/material";
import { isEmpty, round } from "lodash";
import { useSelector } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";

import DateRangePicker from "../DateRangePicker/DateRangePicker.component";
import {
  baseColumns,
  campTypesInfoColumns,
  getCellClassName,
} from "../../assets/constants";
import NavbarGrid from "../NavbarDataGrid/NavbarDataGrid.component";

const Navbar = () => {
  const { campTypesInfo, summaryMetrics, totalOrders } = useSelector(
    (state) => state.metrics
  );
  const [data, setData] = useState({
    columns: [
      {
        field: "totalOrders",
        headerName: "Total Orders",
        align: "center",
        type: "number",
        headerAlign: "center",
      },
      {
        field: "totalBudget",
        headerName: "Total Budget",
        align: "center",
        type: "number",
        headerAlign: "center",
        valueFormatter: ({ value }) => {
          return (
            value && value.toLocaleString("en-US", { maximumFractionDigits: 0 })
          );
        },
      },
      {
        field: "secondaryTotalBudget",
        headerName: "Secondary Total Budget",
        align: "center",
        type: "number",
        headerAlign: "center",
        valueFormatter: ({ value }) => {
          return (
            value && value.toLocaleString("en-US", { maximumFractionDigits: 0 })
          );
        },
      },
      {
        field: "budgetB",
        headerName: "Budget B",
        align: "center",
        type: "number",
        headerAlign: "center",
        valueFormatter: ({ value }) => {
          return `${round(value / 1000, 0)}K`;
        },
      },
      {
        field: "orders",
        headerName: "FB Orders",
        align: "center",
        type: "number",
        headerAlign: "center",
        valueFormatter: ({ value }) => {
          return (
            value && value.toLocaleString("en-US", { maximumFractionDigits: 0 })
          );
        },
      },
      {
        field: "spend",
        headerName: "Total Spend",
        align: "center",
        type: "number",
        headerAlign: "center",
        valueFormatter: ({ value }) => {
          return (
            value && value.toLocaleString("en-US", { maximumFractionDigits: 0 })
          );
        },
      },
      {
        field: "adsRev",
        headerName: "AR Summary",
        align: "center",
        valueFormatter: (params) => {
          if (params.value == null) {
            return "";
          }
          return `${params.value.toFixed(1)}%`;
        },
        type: "number",
        cellClassName: (params) => getCellClassName(params, true, true),
        headerAlign: "center",
      },
      ...baseColumns,
    ],
    rows: isEmpty(summaryMetrics) ? [] : [{ totalOrders, ...summaryMetrics }],
  });
  const handleLogout = () => {
    localStorage.removeItem(process.env.REACT_APP_TOKEN_KEY);
    window.location.reload();
  };
  const [campTypeData, setCampTypeData] = useState({
    columns: campTypesInfoColumns,
    rows: isEmpty(campTypesInfo) ? [] : campTypesInfo,
  });
  useEffect(() => {
    setData({
      ...data,
      rows: isEmpty(summaryMetrics) ? [] : [{ totalOrders, ...summaryMetrics }],
    });
    setCampTypeData({
      ...campTypeData,
      rows: isEmpty(campTypesInfo) ? [] : campTypesInfo,
      sortModel: [
        {
          field: "campType",
          sort: "asc",
        },
      ],
    });
  }, [summaryMetrics, campTypesInfo]);
  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: "#fff" }}>
        <Toolbar className="custom_toolbar">
          <Button variant="outlined" onClick={handleLogout}>
            <LogoutIcon />
          </Button>
          <DateRangePicker />
          <div style={{ height: 45 }}>
            <NavbarGrid style={{ flex: 1 }} data={data} />
          </div>
          <div style={{ height: 145 }}>
            <NavbarGrid style={{ flex: 1 }} data={campTypeData} />
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Navbar;
