import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { loginAction } from "../../slices/auth.slicer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    maxWidth: "400px",
    width: "100%",
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  message: {
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
}));

const LoginPage = () => {
  const dispatch = useDispatch();
  const { loggedIn } = useSelector((state) => state.auth);
  const classes = useStyles();
  const [token, setToken] = useState("");
  const navigate = useNavigate();

  if (loggedIn) {
    navigate("/");
  }

  const handleTokenChange = (event) => {
    setToken(event.target.value);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    dispatch(loginAction(token));
  };

  return (
    <div className={classes.root}>
      <form className={classes.form} onSubmit={handleFormSubmit}>
        {loggedIn && <p className={classes.message}>You are logged in!</p>}
        <TextField
          className={classes.textField}
          label="Token"
          variant="outlined"
          required
          value={token}
          onChange={handleTokenChange}
          error={token.trim() === ""}
          helperText={token.trim() === "" ? "Token is required" : ""}
          type="password"
        />
        <Button type="submit" variant="contained" color="primary">
          Login
        </Button>
      </form>
    </div>
  );
};

export default LoginPage;
