import http from "./index";

const getViews = async () => {
  const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);

  const result = await http.get("/views", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return result.data;
};

const insertView = async (view) => {
  const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);

  const result = await http.post(
    "/views",
    { view },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return result.data;
};

const deleteView = async (id) => {
  const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);

  const result = await http.delete(`/views/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return result.data;
};

export { deleteView, getViews, insertView };
