import http from "./index";

const getAccounts = async () => {
  const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);

  const result = await http.get("/accounts", null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return result.data;
};

export { getAccounts };
