import { Avatar, Link } from "@mui/material";
import { FiberManualRecord } from "@mui/icons-material";
import { round } from "lodash";

const baseUrl = "https://adsmanager.facebook.com/adsmanager/manage/campaigns";

const generateAdsManagerLink = (campaignId, accountId) =>
  `${baseUrl}?act=${accountId}&selected_campaign_ids=${campaignId}&filter_set=CAMPAIGN_GROUP_SELECTED-STRING_SET%1EIN%1E["${campaignId}"]&nav_source=no_referrer`;

const initialState = {
  sorting: {
    sortModel: [
      { field: "typeValue", sort: "asc" },
      { field: "scaleValue", sort: "asc" },
    ],
    filterModel: {
      items: [{ field: "revenue", operator: ">", value: "100" }],
    },
  },
  columns: {
    columnVisibilityModel: {
      status: false,
    },
  },
};
const getCPCClassName = (params) => {
  const { CPC, ytdCpc } = params.row;
  if (CPC && CPC < ytdCpc) {
    return `grid_row--scale--medium`;
  }
  return "cpc--warning";
};
const getCellClassName = (
  params,
  isAdRev = false,
  isSummary = false,
  isCheckingStatus = false,
  isDiffAR = false
) => {
  const { row, value } = params;
  if (isDiffAR) {
    if (Math.abs(value) > 10) {
      return "grid_row--scale--off";
    } else if (Math.abs(value) >= 5) {
      return "grid_row--scale--hold";
    } else {
      return "";
    }
    // {
    //   return "grid_row--scale--max";
    // }
  }
  if (isSummary && isAdRev) {
    if (value >= 90) {
      return "grid_row--scale--off";
    }
    if (value >= 80) {
      return "grid_row--scale--hold";
    }
    if (value >= 70) {
      return "grid_row--scale--optimized";
    }
    if (value < 70) {
      return "grid_row--scale--max";
    }
  }
  if (value == null) {
    return "";
  }
  if (isCheckingStatus) {
    const { status } = row;
    if (status === "ACTIVE") {
      return "grid_row--active";
    }
    return "grid_row--inactive";
  }
  const { typeValue, scaleValue } = row;
  let scaleOption = scaleValue === 0 ? "max" : "hold";
  if (scaleValue === 1) {
    scaleOption = "medium";
  } else if (scaleValue === 3) {
    scaleOption = "off";
  }
  if (typeValue === 0) {
    // scale
    if (!isAdRev) {
      return `grid_row--scale`;
    }
    if (value > 70 && value < 80) {
      return `grid_row--scale--warning`;
    } else if (value >= 80) {
      return `grid_row--scale--shut_down`;
    }
    return `grid_row--scale--${scaleOption}`;
  } else if (typeValue === 1) {
    if (!isAdRev) {
      return `grid_row--optimized`;
    }
    if (value >= 80) {
      return `grid_row--optimized--warning`;
    }
    return `grid_row--optimized--${scaleOption}`;
  } else if (typeValue === 2) {
    return !isAdRev
      ? `grid_row--maintain`
      : `grid_row--maintain--${scaleOption}`;
  } else if (typeValue === 3) {
    return !isAdRev ? `grid_row--test` : `grid_row--test--${scaleOption}`;
  }
  return !isAdRev ? `grid_row--abo` : `grid_row--abo--${scaleOption}`;
};
const baseColumns = [
  {
    field: "ROAS",
    headerName: "ROAS",
    align: "center",
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      return params.value.toFixed(2);
    },
    type: "number",
    headerAlign: "center",
    maxWidth: 80,
  },
  {
    field: "CR%",
    headerName: "CR%",
    align: "center",
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      return `${params.value.toFixed(1)}%`;
    },
    type: "number",
    headerAlign: "center",
    maxWidth: 80,
  },

  {
    field: "CPC",
    headerName: "CPC",
    align: "center",
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      return params.value.toFixed(2);
    },
    type: "number",
    headerAlign: "center",
    cellClassName: (params) => getCPCClassName(params),
    maxWidth: 80,
  },
];
const metadataColumns = [
  {
    field: "ytdCpc",
    headerName: "Prev CPC",
    align: "center",
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      return params.value.toFixed(2);
    },
    type: "number",
    headerAlign: "center",
    maxWidth: 80,
  },
  {
    field: "cpm",
    headerName: "CPM",
    align: "center",
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      return params.value.toFixed(1);
    },
    type: "number",
    headerAlign: "center",
    maxWidth: 80,
  },
  {
    field: "ctr",
    headerName: "CTR",
    align: "center",
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      return params.value.toFixed(2);
    },
    type: "number",
    headerAlign: "center",
    maxWidth: 80,
  },
  {
    field: "CPP",
    headerName: "CPP",
    align: "center",
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      return params.value.toFixed(1);
    },
    type: "number",
    headerAlign: "center",
    maxWidth: 80,
  },
  {
    field: "quantity",
    headerName: "Items Sold",
    align: "center",
    type: "number",
    maxWidth: 80,
  },
  {
    field: "linkClicks",
    headerName: "Clicks",
    align: "center",
    type: "number",
    headerAlign: "center",
    maxWidth: 80,
  },
  {
    field: "AOV",
    headerName: "AOV",
    align: "center",
    type: "number",
    headerAlign: "center",
    maxWidth: 80,
  },
];
const campTypesInfoColumns = [
  {
    field: "campType",
    headerName: "Type",
    align: "center",
    sortComparator: (v1, v2, param1, param2) =>
      param1.api.getCellValue(param1.id, "typeValue") -
      param2.api.getCellValue(param2.id, "typeValue"),
  },
  {
    field: "campTypeProportion",
    headerName: "% Camp Num",
    align: "center",
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      return `${params.value.toFixed(1)}%`;
    },
  },
  {
    field: "campTypeSpendProportion",
    headerName: "% Spend",
    align: "center",
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      return `${params.value.toFixed(1)}%`;
    },
    type: "number",
  },
  {
    field: "campTypeROAS",
    headerName: "ROAS",
    align: "center",
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      return params.value.toFixed(1);
    },
  },
];

const generateColumns = (summaryData) => {
  const { spend: totalSpend } = summaryData;
  const columns = [
    {
      field: "campaign_name",
      headerName: "Camp Name",
      minWidth: 220,
      filterable: true,
      cellClassName: (params) => getCellClassName(params, false, false, true),
      headerAlign: "center",
      sticky: "left",
      renderCell: (params) => {
        const { account_id, campaign_id, status } = params.row;
        const campUrl = generateAdsManagerLink(campaign_id, account_id);

        return (
          <Link href={campUrl} target="_blank" rel="noopener noreferrer">
            <FiberManualRecord
              fontSize="small"
              sx={{
                mr: 1,
                color: status === "ACTIVE" ? "#4caf50" : "#d9182e",
              }}
            />
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      sticky: "center",
      type: "string",
    },
    {
      field: "created_time",
      headerName: "Created Time",
      renderCell: (params) =>
        !params.value ? "" : new Date(params.value).toISOString().split("T")[0],
      valueGetter: (params) =>
        !params.row.created_time ? "" : new Date(params.row.created_time),
      headerAlign: "center",
      sticky: "left",
      type: "date",
    },
    {
      field: "imageUrl",
      headerName: "Image",
      width: 85,
      renderCell: (params) => (
        <Avatar
          src={params.value}
          variant="square"
          sx={{ width: 70, height: 70 }}
        />
      ),
      headerAlign: "center",
      sticky: "left",
    },
    {
      field: "campType",
      headerName: "Camp Type",
      filterable: true,
      cellClassName: (params) => getCellClassName(params),
      sortComparator: (v1, v2, param1, param2) =>
        param1.api.getCellValue(param1.id, "typeValue") -
        param2.api.getCellValue(param2.id, "typeValue"),
      headerAlign: "center",
      maxWidth: 80,
      sticky: "left",
    },
    {
      field: "initBudget",
      headerName: "Init Budget",
      align: "center",
      valueFormatter: ({ value }) => {
        return (
          value?.toLocaleString("en-US", { maximumFractionDigits: 0 }) || ""
        );
      },
      type: "number",
      headerAlign: "center",
      maxWidth: 80,
    },
    {
      field: "budget",
      headerName: "Budget",
      align: "center",
      valueFormatter: ({ value }) => {
        return (
          value?.toLocaleString("en-US", { maximumFractionDigits: 0 }) || ""
        );
      },
      type: "number",
      headerAlign: "center",
      maxWidth: 80,
    },
    {
      field: "spend",
      headerName: "Spend",
      align: "center",
      valueFormatter: ({ value }) => {
        return (
          value?.toLocaleString("en-US", { maximumFractionDigits: 0 }) || ""
        );
      },
      type: "number",
      headerAlign: "center",
      maxWidth: 80,
    },
    {
      field: "ytdSpend",
      headerName: "YTD Spend",
      align: "center",
      valueFormatter: ({ value }) => {
        return (
          value?.toLocaleString("en-US", { maximumFractionDigits: 0 }) || ""
        );
      },
      type: "number",
      headerAlign: "center",
      maxWidth: 80,
    },
    {
      field: "ytdAR",
      headerName: "YTD AR",
      align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return `${params.value.toFixed(0)}%`;
      },
      type: "number",
      headerAlign: "center",
      maxWidth: 80,
    },
    {
      field: "percentSpent",
      headerName: "%Spend",
      align: "center",
      valueGetter: (params) => {
        const dataItem = params.row;
        const countProperty = "spend"; // replace with the name of the property in data that you want to count
        return dataItem[countProperty] / totalSpend;
      },
      valueFormatter: ({ value }) => `${(value * 100).toFixed(1)}%`,
      type: "number",
    },
    {
      field: "revenue",
      headerName: "Rev",
      align: "center",
      type: "number",
      // valueFormatter: (value) => value.row.revenue.toFixed(2),
      valueFormatter: ({ value }) => {
        return (
          round(value, 0)?.toLocaleString("en-US", {
            maximumFractionDigits: 0,
          }) || 0
        );
      },
      headerAlign: "center",
      maxWidth: 80,
    },
    {
      field: "orders",
      headerName: "Orders",
      align: "center",
      type: "number",
      headerAlign: "center",
      maxWidth: 100,
      valueGetter: ({ row }) => round(row.orders || 0, 0),
    },
    {
      field: "Ads/Rev",
      headerName: "AR",
      align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return `${params.value.toFixed(0)}%`;
      },
      type: "number",
      headerAlign: "center",
      cellClassName: (params) => getCellClassName(params, true),
      sortComparator: (v1, v2, param1, param2) =>
        param1.api.getCellValue(param1.id, "Ads/Rev") -
        param2.api.getCellValue(param2.id, "Ads/Rev"),
      maxWidth: 80,
    },
    {
      field: "adsRevFB",
      headerName: "AR FB",
      align: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return `${params.value.toFixed(0)}%`;
      },
      type: "number",
      headerAlign: "center",
      // cellClassName: (params) => getCellClassName(params, true),
      sortComparator: (v1, v2, param1, param2) =>
        param1.api.getCellValue(param1.id, "adsRevFB") -
        param2.api.getCellValue(param2.id, "adsRevFB"),
      maxWidth: 80,
    },
    {
      field: "diffAR",
      headerName: "Diff AR",
      align: "center",
      type: "number",
      maxWidth: 80,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return `${params.value.toFixed(0)}%`;
      },
      cellClassName: (params) =>
        getCellClassName(params, false, false, false, true),
    },
    {
      field: "ordersFB",
      headerName: "Orders FB",
      align: "center",
      type: "number",
      maxWidth: 80,
    },
    ...baseColumns,
    ...metadataColumns,
    {
      field: "account_name",
      headerName: "Acc",
      resizable: true,
      headerAlign: "center",
      maxWidth: 80,
    },
    {
      field: "store",
      headerName: "Store",
      resizable: true,
      headerAlign: "center",
      maxWidth: 80,
    },
    {
      field: "category",
      headerName: "Category",
      resizable: true,
      headerAlign: "center",
      maxWidth: 80,
    },
    {
      field: "secondImageUrl",
      headerName: "2nd Ad",
      renderCell: (params) => (
        <Avatar
          src={params.value}
          variant="square"
          sx={{ width: 70, height: 70 }}
        />
      ),
      headerAlign: "center",
      sticky: "left",
    },
    {
      field: "thirdImageUrl",
      headerName: "3rd Ad",
      renderCell: (params) => (
        <Avatar
          src={params.value}
          variant="square"
          sx={{ width: 70, height: 70 }}
        />
      ),
      headerAlign: "center",
      sticky: "left",
    },
  ];
  return columns;
};

export {
  baseColumns,
  getCellClassName,
  initialState,
  campTypesInfoColumns,
  generateColumns,
};
