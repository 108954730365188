import React, { useState } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  useTheme,
  Modal,
} from "@mui/material";
// import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import ModalDialog from "@mui/joy/ModalDialog";
import { useDispatch, useSelector } from "react-redux";
import { DialogTitle, ModalClose } from "@mui/joy";
import { isEmpty, sumBy, toNumber, round, map, isNull } from "lodash";

import { updateMassCampaignsAction } from "../../slices/metrics.slicer";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const FilterCampAdsRev = ({
  openModal,
  setOpenModal,
  onClickCancelIconHandler,
}) => {
  const { accountNames: accountNamesState } = useSelector(
    (state) => state.accounts
  );
  const { selectedRows } = useSelector((state) => state.views);
  const dispatch = useDispatch();
  const [accountNames, setAccountNames] = useState([]);
  const theme = useTheme();
  const [minSpent, setMinSpent] = useState(null);
  const [percentAdjust, setPercentAdjust] = useState(null);
  const [adsRev, setAdsRev] = useState(null);
  const [openMinAdsRev, setMinOpenAdsRev] = useState(false);
  const [useSelectedRows, setUseSelectedRows] = useState(true);
  const [showEst, setShowEst] = useState(false);
  const [confirmUpdate, setConfirmUpdate] = useState(false);
  const [hideCheck, setHideCheck] = useState(false);
  const [estimateAdjustAmount, setEstimateAdjustAmount] = useState(0);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setAccountNames(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const checkAdjustedAmount = () => {
    // get the sum budget of selected rows, then set the estimate adjust amount
    const sumBudget = sumBy(selectedRows, "budget");
    setEstimateAdjustAmount((sumBudget * (toNumber(percentAdjust) || 0)) / 100);
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    const payload = {
      // ...(openMinAdsRev
      //   ? { minAdsRev: toNumber(adsRev) || 0 }
      //   : { maxAdsRev: toNumber(adsRev) || 0 }),
      // minSpent: toNumber(minSpent) || 0,
      // accountNames,
      adjustPercentage: toNumber(percentAdjust) || 0,
      data: map(selectedRows, (i) => {
        const {
          campaign_id,

          account_id,
        } = i;
        return {
          id: campaign_id,

          accountId: account_id,
        };
      }),
      // useSelectedCamps: useSelectedRows,
    };
    console.log(payload);
    dispatch(updateMassCampaignsAction(payload));
    setOpenModal(false);
  };
  return (
    openModal && (
      <Modal
        open={openModal}
        onBackdropClick={() => setOpenModal(false)}
        onClose={onClickCancelIconHandler}
      >
        <ModalDialog size="lg" variant="plain" layout="center">
          <ModalClose onClick={() => setOpenModal(false)} />
          <DialogTitle>Update Mass</DialogTitle>
          <form onSubmit={onSubmitHandler}>
            <Stack spacing={2}>
              {/* {!isEmpty(selectedRows) && (
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useSelectedRows}
                        name="minAdsRev"
                        onClick={() => setUseSelectedRows(!useSelectedRows)}
                      />
                    }
                    label="Using Selected Rows"
                  />
                </FormControl>
              )} */}
              {/* {!useSelectedRows && (
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel id="demo-select-small-label">Accs</InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={accountNames}
                    onChange={handleChange}
                    input={<OutlinedInput label="Name" />}
                    MenuProps={MenuProps}
                    sx={{ minHeight: 60 }}
                  >
                    {accountNamesState?.map((accountName) => {
                      return (
                        <MenuItem
                          value={accountName}
                          style={getStyles(accountName, accountNames, theme)}
                        >
                          {accountName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
              {!useSelectedRows && (
                <FormControl>
                  <FormLabel>Min Spent</FormLabel>
                  <Input
                    autoFocus
                    type="number"
                    required
                    onChange={(e) => {
                      setMinSpent(e.target.value);
                    }}
                  />
                </FormControl>
              )}

              {!useSelectedRows && (
                <FormGroup
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={openMinAdsRev}
                        name="minAdsRev"
                        onClick={() => setMinOpenAdsRev(!openMinAdsRev)}
                      />
                    }
                    label="Min Ads/Rev"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!openMinAdsRev}
                        name="maxAdsRev"
                        onClick={() => setMinOpenAdsRev(!openMinAdsRev)}
                      />
                    }
                    label="Max Ads/Rev"
                  />
                </FormGroup>
              )}
              {!useSelectedRows && (
                <FormControl>
                  {openMinAdsRev && (
                    <>
                      <TextField
                        color="info"
                        label="Min Ads/Rev"
                        id="min-ads-rev"
                        required={openMinAdsRev}
                        onChange={(e) => {
                          setAdsRev(e.target.value);
                        }}
                        type="number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">%</InputAdornment>
                          ),
                        }}
                      />
                    </>
                  )}
                  {!openMinAdsRev && (
                    <>
                      <TextField
                        color="success"
                        label="Max Ads/Rev"
                        id="max-ads-rev"
                        type="number"
                        required={!openMinAdsRev}
                        onChange={(e) => {
                          setAdsRev(e.target.value);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">%</InputAdornment>
                          ),
                        }}
                      />
                    </>
                  )}
                </FormControl>
              )} */}
              <FormControl>
                <TextField
                  color="info"
                  label="Adjust Percent"
                  id="min-ads-rev"
                  required={true}
                  onChange={(e) => {
                    setPercentAdjust(e.target.value);
                  }}
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                  }}
                />
              </FormControl>
              {showEst && (
                <>
                  <div>{`Est to adjust: ${round(
                    estimateAdjustAmount,
                    0
                  ).toLocaleString()}$`}</div>
                  <Stack spacing={2} direction="row">
                    <Button variant="contained" color="warning" type="submit">
                      Confirm
                    </Button>
                    <Button
                      variant="contained"
                      color="info"
                      onClick={() => setOpenModal(false)}
                      type="button"
                    >
                      Cancel
                    </Button>
                  </Stack>
                </>
              )}
              {/* {!useSelectedRows && (
                <Button type="submit" variant="contained">
                  Submit
                </Button>
              )} */}

              {useSelectedRows &&
                !hideCheck &&
                (!confirmUpdate ? (
                  <Button
                    type="button"
                    color="info"
                    variant="contained"
                    onClick={() => {
                      setShowEst(!showEst);
                      checkAdjustedAmount();
                      setHideCheck(true);
                    }}
                    disabled={isNull(percentAdjust)}
                  >
                    Check Total Adjust
                  </Button>
                ) : (
                  <Button type="submit" variant="contained">
                    Submit
                  </Button>
                ))}
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    )
  );
};

FilterCampAdsRev.propTypes = {
  openModal: PropTypes.bool.isRequired,
  action: PropTypes.string.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  onClickCancelIconHandler: PropTypes.func.isRequired,
};

export default FilterCampAdsRev;
