import axios from "axios";

const baseURL =
  process.env.REACT_APP_NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_BASE_AXIOS_URL ||
      process.env.REACT_APP_DEV_BASE_AXIOS_URL
    : process.env.REACT_APP_DEV_BASE_AXIOS_URL;

// Encrypt the token using JWT
const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);
export default axios.create({
  baseURL,
  headers: {
    "Content-type": "application/json",
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
  },
});
