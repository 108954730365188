import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { login } from "../services/auth";

const initialState = {
  isLoading: false,
  loggedIn: false,
  readable: false,
  writable: false,
  username: null,
};

export const loginAction = createAsyncThunk("auth/login", async (token) =>
  login({ token })
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state, action) {
      state.loggedIn = false;
      state.readable = false;
      state.writable = false;
      state.username = null;
    },
  },
  extraReducers: {
    [loginAction.pending]: (state, action) => {
      state.isLoading = true;
    },
    [loginAction.rejected]: (state, action) => {
      state.isLoading = false;
      state.loggedIn = false;
      state.readable = false;
      state.writable = false;
      state.username = null;
      toast.error("Invalid token");
    },
    [loginAction.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.loggedIn = true;
      state.readable = action.payload.readable;
      state.writable = action.payload.writable;
      state.username = action.payload.username;
      // store token in local storage

      toast.success("Login success");
    },
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
