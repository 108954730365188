import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { map, reduce, reject } from "lodash";

import { deleteView, getViews, insertView } from "../services/views";

const initialState = {
  isLoading: false,
  list: [],
  activeView: null,
  filterModel: null,
  gridKey: null,
  selectedRows: [],
  selectedRowsMetrics: null,
};

export const getViewsAction = createAsyncThunk("views/get_all", async () =>
  getViews()
);

export const insertViewAction = createAsyncThunk("views/insert", async (view) =>
  insertView(view)
);

export const deleteViewAction = createAsyncThunk("views/delete", async (id) =>
  deleteView(id)
);

const viewsSlice = createSlice({
  name: "views",
  initialState,
  reducers: {
    setActiveView(state, action) {
      state.activeView = action.payload;
      state.gridKey = action.payload;
      state.selectedRows = [];
      state.selectedRowsMetrics = null;
    },
    resetView(state, action) {
      state.activeView = null;
      state.gridKey = null;
      state.selectedRows = [];
      state.selectedRowsMetrics = null;
    },
    setGridKey(state, action) {
      state.gridKey = action.payload;
    },
    updateSelectedRows(state, action) {
      const { payload } = action;
      const refactoredData = map(payload, (item) => ({
        ...item,
        campaignId: item.campaign_id,
        accountId: item.account_id,
      }));
      state.selectedRows = refactoredData;
      // counting metrics of current selected rows
      // orders, rev, spend, ads/rev, roas, cr%,cpc
      const metrics = reduce(
        refactoredData,
        (result, item) => {
          result.orders += item.finalOrders;
          result.finalRev += item.finalRev;
          result.spend += item.spend;
          result.linkClicks += item.linkClicks;
          result.adsRev = !result.revenue
            ? 1000
            : (result.spend / result.revenue) * 100;
          result.roas = !result.spend ? 1000 : result.revenue / result.spend;
          result.cr = !result.linkClicks
            ? 0
            : (result.orders / result.linkClicks) * 100;
          result.cpc = !result.linkClicks
            ? 0
            : result.spend / result.linkClicks;
          return result;
        },
        {
          orders: 0,
          revenue: 0,
          spend: 0,
          adsRev: 0,
          roas: 0,
          cr: 0,
          cpc: 0,
          linkClicks: 0,
        }
      );
      state.selectedRowsMetrics = metrics;
    },
    filterUpdate(state, action) {
      const ids = map(action.payload, "id");
      // remove in selectedRows if not in ids
      const { selectedRows } = state;
      console.log(`Pre: ${selectedRows.length}, ids: ${ids.length}`);
      const newSelectedRows = reject(
        selectedRows,
        (row) => !ids.includes(row.id)
      );
      state.selectedRows = newSelectedRows;
      console.log(`Post: ${newSelectedRows.length}`);
      // counting metrics of current selected rows
      const metrics = reduce(
        newSelectedRows,
        (result, item) => {
          result.orders += item.finalOrders;
          result.finalRev += item.finalRev;
          result.spend += item.spend;
          result.linkClicks += item.linkClicks;
          result.adsRev = !result.revenue
            ? 1000
            : (result.spend / result.revenue) * 100;
          result.roas = !result.spend ? 1000 : result.revenue / result.spend;
          result.cr = !result.linkClicks
            ? 0
            : (result.orders / result.linkClicks) * 100;
          result.cpc = !result.linkClicks
            ? 0
            : result.spend / result.linkClicks;
          return result;
        },
        {
          orders: 0,
          revenue: 0,
          spend: 0,
          adsRev: 0,
          roas: 0,
          cr: 0,
          cpc: 0,
          linkClicks: 0,
        }
      );
      state.selectedRowsMetrics = metrics;
      return state;
    },
  },
  extraReducers: {
    [getViewsAction.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getViewsAction.rejected]: (state, action) => {
      state.isLoading = true;
    },
    [getViewsAction.fulfilled]: (state, action) => {
      state.list = action.payload;
      state.isLoading = false;
      state.selectedRows = [];
    },
    [insertViewAction.pending]: (state, action) => {
      state.isLoading = true;
    },
    [insertViewAction.rejected]: (state, action) => {
      state.isLoading = true;
    },
    [insertViewAction.fulfilled]: (state, action) => {
      state.list.push(action.payload);
      state.isLoading = false;
      state.selectedRows = [];
    },
    [deleteViewAction.pending]: (state, action) => {
      state.isLoading = true;
    },
    [deleteViewAction.rejected]: (state, action) => {
      state.isLoading = true;
    },
    [deleteViewAction.fulfilled]: (state, action) => {
      state.list = reject(state.list, { id: action.payload });
      state.isLoading = false;
      state.activeView = null;
      state.gridKey = null;
      state.selectedRows = [];
      state.selectedRowsMetrics = null;
    },
  },
});

export const {
  filterUpdate,
  resetView,
  setActiveView,
  setGridKey,
  updateSelectedRows,
} = viewsSlice.actions;

export default viewsSlice.reducer;
