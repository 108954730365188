import http from "./index";

const login = async ({ token }) => {
  localStorage.setItem(process.env.REACT_APP_TOKEN_KEY, token);

  const result = await http.post("/auth/log-in", {
    token,
  });
  return result.data;
};

export { login };
