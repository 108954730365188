import React, { useState } from "react";

import {
  Button,
  FormControl,
  InputAdornment,
  Stack,
  TextField,
  useTheme,
  Modal,
} from "@mui/material";
// import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import ModalDialog from "@mui/joy/ModalDialog";
import { useDispatch, useSelector } from "react-redux";
import { DialogTitle, ModalClose } from "@mui/joy";
import { toNumber, map, isNull, set } from "lodash";

import { resetCooldownAction } from "../../slices/metrics.slicer";

const ResetCooldownModal = ({
  openModal,
  setOpenModal,
  onClickCancelIconHandler,
}) => {
  const { accountNames: accountNamesState } = useSelector(
    (state) => state.accounts
  );
  const { selectedRows } = useSelector((state) => state.views);
  const dispatch = useDispatch();
  const [accountNames, setAccountNames] = useState([]);
  const theme = useTheme();
  const [minSpent, setMinSpent] = useState(null);
  const [secretCode, setSecretCode] = useState(null);
  const [adsRev, setAdsRev] = useState(null);
  const [openMinAdsRev, setMinOpenAdsRev] = useState(false);
  const [confirmUpdate, setConfirmUpdate] = useState(false);
  const [hideCheck, setHideCheck] = useState(false);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    console.log("secretCode", secretCode);
    dispatch(resetCooldownAction(secretCode));
    setOpenModal(false);
  };
  return (
    openModal && (
      <Modal
        open={openModal}
        onBackdropClick={() => setOpenModal(false)}
        onClose={onClickCancelIconHandler}
      >
        <ModalDialog size="lg" variant="plain" layout="center">
          <ModalClose onClick={() => setOpenModal(false)} />
          <DialogTitle>Update Mass</DialogTitle>
          <form onSubmit={onSubmitHandler}>
            <Stack spacing={2}>
              <FormControl>
                <TextField
                  color="info"
                  label="Key in Secret Code"
                  id="secret-code"
                  required={true}
                  onChange={(e) => {
                    setSecretCode(e.target.value);
                  }}
                  type="password"
                />
              </FormControl>

              <Button type="submit" variant="contained">
                Submit
              </Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    )
  );
};

ResetCooldownModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  onClickCancelIconHandler: PropTypes.func.isRequired,
};

export default ResetCooldownModal;
