import React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";

import PropTypes from "prop-types";

const NavbarDataGrid = ({ data }) => {
  return (
    <DataGridPro
      columnHeaderHeight={20}
      hideFooter
      {...data}
      disableRowSelectionOnClick
      showCellVerticalBorder
      showColumnVerticalBorder
      getRowHeight={() => "auto"}
      disableColumnFilter
      disableColumnResize
    />
  );
};

NavbarDataGrid.propTypes = {
  data: PropTypes.object.isRequired,
};

export default NavbarDataGrid;
