import React, { useState } from "react";

import {
  Button,
  FormControl,
  InputAdornment,
  Stack,
  TextField,
  useTheme,
  Modal,
} from "@mui/material";
// import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import ModalDialog from "@mui/joy/ModalDialog";
import { useDispatch, useSelector } from "react-redux";
import { DialogTitle, ModalClose } from "@mui/joy";
import { toNumber, map, isNull, set } from "lodash";

import { updateMaxBudgetAction } from "../../slices/metrics.slicer";

const UpdateMaxBudgetModal = ({
  openModal,
  setOpenModal,
  onClickCancelIconHandler,
}) => {
  const dispatch = useDispatch();

  const [maxBudget, setMaxBudget] = useState(15000);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(updateMaxBudgetAction(maxBudget));
    setOpenModal(false);
  };
  return (
    openModal && (
      <Modal
        open={openModal}
        onBackdropClick={() => setOpenModal(false)}
        onClose={onClickCancelIconHandler}
      >
        <ModalDialog size="lg" variant="plain" layout="center">
          <ModalClose onClick={() => setOpenModal(false)} />
          <DialogTitle>Update Mass</DialogTitle>
          <form onSubmit={onSubmitHandler}>
            <Stack spacing={2}>
              <FormControl>
                <TextField
                  color="info"
                  label="Key in the new max budget"
                  id="maxBudget"
                  required={true}
                  onChange={(e) => {
                    setMaxBudget(e.target.value);
                  }}
                  defaultValue={maxBudget}
                  type="number"
                />
              </FormControl>

              <Button type="submit" variant="contained">
                Submit
              </Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    )
  );
};

UpdateMaxBudgetModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  onClickCancelIconHandler: PropTypes.func.isRequired,
};

export default UpdateMaxBudgetModal;
