import { configureStore } from "@reduxjs/toolkit";

import metricsReducer from "./slices/metrics.slicer";
import viewsReducer from "./slices/views.slicer";
import authSlicer from "./slices/auth.slicer";
import accountSlicer from "./slices/account.slicer";

const reducer = {
  metrics: metricsReducer,
  views: viewsReducer,
  auth: authSlicer,
  accounts: accountSlicer,
};

const store = configureStore({
  reducer: reducer,
  devTools: true,
});

export default store;
