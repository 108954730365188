import React, { useState } from "react";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import { Typography, Grid } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { updateCampaignsStatusAction as updateCampaignsStatus } from "../../slices/metrics.slicer";
import "./ChangeCampStatusModal.Component.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ChangeCampStatusModal = ({
  openModal,
  setOpenModal,
  onClickCancelIconHandler,
  payload,
}) => {
  const dispatch = useDispatch();
  const { selectedRowsMetrics } = useSelector((state) => state.views);
  const [renderConfirm, setRenderConfirm] = useState(true);
  const onConfirmHandler = (action) => {
    dispatch(updateCampaignsStatus({ campaigns: payload, action }));
    setOpenModal(false);
  };
  return (
    openModal && (
      <Modal
        open={openModal}
        onBackdropClick={() => setOpenModal(false)}
        onClose={onClickCancelIconHandler}
      >
        <Box sx={style}>
          <h2 id="child-modal-title">Bạn chắc là muốn chỉnh tụi mình?</h2>
          <div>
            <Typography variant="h6">Tụi mình đang perform vầy nè</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography>Orders: {selectedRowsMetrics.orders}</Typography>
                <Typography>
                  Revenue: {`${selectedRowsMetrics.revenue.toFixed(0)}$`}
                </Typography>
                <Typography>
                  Spend: {`${selectedRowsMetrics.spend.toFixed(0)}$`}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  Ads/Rev: {`${selectedRowsMetrics.adsRev.toFixed(2)}%`}
                </Typography>
                <Typography>
                  CR: {`${selectedRowsMetrics.cr.toFixed(2)}%`}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className="container">
            <Button
              onClick={onClickCancelIconHandler}
              variant="contained"
              color="error"
            >
              Mình nhầm!
            </Button>
            <Button
              onClick={() => onConfirmHandler(true)}
              variant="contained"
              color="success"
            >
              Bật
            </Button>
            <Button
              onClick={() => onConfirmHandler(false)}
              variant="contained"
              color="error"
            >
              Tắt
            </Button>
          </div>
        </Box>
      </Modal>
    )
  );
};

ChangeCampStatusModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  action: PropTypes.string.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  onClickCancelIconHandler: PropTypes.func.isRequired,
  payload: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ChangeCampStatusModal;
